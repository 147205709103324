header {
	position: relative;
}

header > nav {
	background: #212121;
	width: 100%;
	height: 80px;
	position: fixed;
	top: 0;
	left: 0;
	box-shadow: 0 3px 5px #000;
	padding: 1rem 1.5rem;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	z-index: 100;
}

.nav__item {
	list-style: none;
	width: 100%;
	display: flex;
	flex-direction: row nowrap;
	/* justify-content: space-around; */
}

nav a {
	font-size: 1.5rem;
	margin: 0 2rem;
	color: #fff;
	cursor: pointer;
	text-decoration: none;
	border-top: 1px solid rgba(255, 255, 255, 0);
}

nav a:hover {
	border-top: 1px solid rgba(255, 255, 255, 1);
}

.hide_on_mobile {
	display: none;
}